// import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react'
// import { RichText } from 'prismic-reactjs'
import { graphql } from 'gatsby';
import LayoutES from '../../components/layouts-es';

import BannerES from '../../components/home-es/Banner'

import DescriptionES from '../../components/home-es/Description';

import FactsES from '../../components/home-es/Facts';

import PricingES from '../../components/home-es/Pricing';

import TestimonialES from '../../components/home-es/Testimonial';

import FeatureCarouselES from '../../components/home-es/FeatureCarousel'
// import BlogPosts from '../../components/BlogPosts'
import store from '../../store'
import { navigate } from "gatsby"

// Query for the Blog Home content in Prismic
export const query = graphql`
{
  bannerImages: allFile(filter: {relativeDirectory: {eq: "home/banner"}}) {
    edges {
      node {
        childImageSharp {
          fixed(width: 733) {
            ...GatsbyImageSharpFixed
          }
        }
        name
      }
    }
  }
  descImages: allFile(filter: {relativeDirectory: {eq: "home/description"}}) {
    edges {
      node {
        childImageSharp {
          fixed(width: 790) {
            ...GatsbyImageSharpFixed
          }
        }
        name
      }
    }
  }
  descImageFluid: allFile(filter: {relativeDirectory: {eq: "home/description"}}) {
    edges {
      node {
        childImageSharp {
          fluid(maxWidth: 790) {
            ...GatsbyImageSharpFluid
          }
        }
        name
      }
    }
  }
  testImages: allFile(filter: {relativeDirectory: {eq: "home/testimonials"}}) {
    edges {
      node {
        childImageSharp {
          fixed(height: 387) {
            ...GatsbyImageSharpFixed
          }
        }
        name
      }
    }
  }
}
`


export default ({ data }) => {
  const [preferredLanguage, setPreferredLanguage] = useState("esp")
  if(typeof localStorage !== 'undefined'){
    localStorage.setItem('preferredLanguage', 'esp');
  }
  store.dispatch({type: 'preferredLanguage', payload: 'esp'})
  /**
   * Whenever there is a change in the language selected from the Dropdown component, there is a dispatch of the current selected language
   * when that changes this callback function runs
   * and to stop listening, invoke the function returned by the subscribe
   */
  const unsubscribe = store.subscribe(() => {
    setPreferredLanguage(store.getState().preferredLanguage);
    if(preferredLanguage !== store.getState().preferredLanguage){
      unsubscribe();
    }
  })
  /**
   * whenever the component is dismounted then stop listening to the changes in the preferred language states
   */
  useEffect(() => {
    return () => { 
      unsubscribe() 
    }
  }, [])
  
  if(preferredLanguage == 'eng'){
    navigate('/')
  }

  return(
    <LayoutES>
      <BannerES bannerImages={data.bannerImages.edges}/>
      <DescriptionES descImages={data.descImages.edges} fluidImages={data.descImageFluid.edges}/>
      {/* <Features /> */}
      <FeatureCarouselES/>
      <FactsES />
      <PricingES />
      <TestimonialES testImages={data.testImages.edges} />
    </LayoutES>
  ) 
}
